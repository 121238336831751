import {createStore} from 'vuex'
import {api} from "@/service/Api";
import {helper} from "@/service/Helper";


function loadUserFromStorage() {

    // localStorage.getItem("user") && localStorage.getItem("user") !== "undefined" && localStorage.getItem("user") !== "null" ? new User(JSON.parse(localStorage.getItem("user")!)) : null
    return null
}

export default createStore({
    state: {
        isLoading: false,
        userToken: null as any,
        userRefreshToken: null as any,
        startText: sessionStorage.getItem('startText') ? sessionStorage.getItem('startText') : '15-10-2024 12:00:00',
        endText: sessionStorage.getItem('endText') ? sessionStorage.getItem('endText') : '09-11-2024 15:00:00',
        ticket: sessionStorage.getItem('ticket') ? JSON.parse(sessionStorage.getItem('ticket')!) : false,
        algo: '',
        state: 'prod',
        at: null as any,
        uuid: '',
        allowScanner: false,
        extraLink: sessionStorage.getItem('extraLink') ? JSON.parse(sessionStorage.getItem('extraLink')!) : false,
        request: '',
        popups: [],
        showCategory: false,
        categories: [] as any[],
        notifications: [],
        lang: 'fr',
        showInvitation: true,
        sleep: (ms: number) => {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
    },
    getters: {
        startAt: (state, getters) => (forced = false) => {
            return new Date(state.startText!);
        },

        isLocal: (state, getters) => (forced = false) => {
            if (window.location.origin.includes('://192.168.') || window.location.origin.includes('://127.0.0.1')
                || window.location.origin.includes('://localhost')) {
                state.allowScanner = true
                return true
            }
            state.allowScanner = false
            return false
        },
        isVoteAllowed: (state, getters) => (forced = false) => {
            if (state.state === 'dev') {
                return true
            }
            const startAt = helper.parseDate(state.startText!);
            const endAt = helper.parseDate(state.endText!);
            const now = new Date()
            return (now.getTime() >= startAt.getTime()) && (now.getTime() <= endAt.getTime())
        },
        loadData: (state, getters) => async (forced = false) => {
            // forced = true
            if (forced) {
                localStorage.removeItem('popupShown')
            }
            if (state.categories.length > 0 && !forced) {
                return state.categories;
            }

            const local: any = sessionStorage.getItem('categories')
            // console.log(local)
            if (local && !forced) {
                try {
                    const categories = JSON.parse(local)
                    if (categories) {
                        state.categories = categories
                        return state.categories
                    }
                } catch (e) {
                }
            }
            const res = await api.get(api.core, "get/data");

            if (res && res.data && res.data.status === 'success') {
                if (res.data.categories) {
                    state.categories = res.data.categories;
                }

                if (!helper.empty(res.data.extraLink)) {
                    state.extraLink = res.data.extraLink
                    sessionStorage.setItem('extraLink', res.data.extraLink)
                    if (!state.extraLink) {
                        sessionStorage.removeItem('popupShown')
                    }
                }

                if (!helper.empty(res.data.ticket)) {
                    state.ticket = res.data.ticket
                    sessionStorage.setItem('ticket', res.data.ticket)
                    if (!state.extraLink) {
                        sessionStorage.removeItem('popupShown')
                    }
                }

                if (res.data.startAt) {
                    state.startText = res.data.startAt
                    sessionStorage.setItem('startText', res.data.startAt)
                }
                if (res.data.endAt) {
                    state.endText = res.data.endAt
                    sessionStorage.setItem('endText', res.data.endAt)
                }
                sessionStorage.setItem('categories', JSON.stringify(res.data.categories))
                if (res.data.version) {
                    let version = sessionStorage.getItem('version')
                    sessionStorage.setItem('version', res.data.version)
                    if (version != res.data.version) {
                        window.location.reload()
                    }
                }
            }
            return state;
        }
    },
    mutations: {
        setAlgo(state: any, algo: string) {
            state.algo = algo
        },
        loading(state: any, html = '') {
            state.isLoading = true;
            state.loadingHtml = html
        },
        stopLoading(state: any) {
            state.isLoading = false;
            state.loadingHtml = ''
        },
        removePopup(state: any, id) {
            const index = state.popups.findIndex((popup: any) => {
                return popup.id === id;
            });
            if (index !== -1) {
                state.popups.splice(index, 1);
            }
        },
        pushPopup(state: any, popup) {
            let index = state.popups.findIndex((popup: any) => {
                return popup.id === popup.id || (popup.title === popup.title && popup.content === popup.content);
            });
            if (index !== -1) {
                state.popups.splice(index, 1);
            }
            state.popups.push(popup)

        }
    },
    actions: {},
    modules: {}
})
