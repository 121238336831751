import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import adminRoutes from "@/router/admin";
import {auth} from "@/service/Auth";

let routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/rubicon',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/preview/:var1/:var2',
        name: 'preview',
        component: () => import('@/views/app/Preview.vue'),
    },
    {
        path: '/top/preview/:var1/:var2',
        name: 'top-preview',
        component: () => import('@/views/app/PreviewTop.vue'),
    },
    {
        path: '/je-veux-mon-ticket-gratuit',
        name: 'free-ticket',
        component: () => import('@/views/FreeTicket.vue'),
        meta: {
            title: 'INVITATION GRATUITE VILLAGE PRIMUD',
        },
    },
    {
        path: '/categorie/:category',
        name: 'nomine-list',
        component: () => import('@/views/CategoryView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]
routes = routes.concat(adminRoutes)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
