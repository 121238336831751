<template>
  <div @click="closePopup" style="top:0;left:0;backdrop-filter: blur(5px);"
       class="w-100 h-100 position-fixed d-flex cursor-pointer justify-content-center align-items-center">
    <div class="code m-5 w-400px d-flex rounded flex-column position-relative py-5"
         style="z-index: 5;position: relative">
      <div style="position: absolute;left:5px;top:5px" class="d-flex justify-content-between w-100 pe-5">
      </div>

      <div class="d-flex align-items-center justify-content-center">
        <img id="primud-logo" src="/img/primud.png" alt="">
        <h1 class="label ms-2" style="font-size: 3rem;text-align: left">
            <span class="text-uppercase" style="color:#d99c3b">
            édition
              </span>
          <br>
          <span class="text-uppercase" style="color:#d99c3b">
            2024
              </span>
        </h1>
      </div>
      <div class="honey-back fs-2x text-uppercase fw-bolder my-5">
        LE BEFORE PRIMUD
        <br>
        A DEBUTé
      </div>
      <div class="flex-grow-1">
        <div class="text-center fs-2x" style="color:#d99c3b">
          Veuillez vous rendre au parc des expositions
        </div>
        <div class="d-flex justify-content-center">
          <div class="text-center fs-2x fw-bolder text-uppercase mt-1 pt-2"
               style="color:#d99c3b;border-top:1px solid #d99c3b">
            MUNI DE VOS TICKETS D'ENTREE
          </div>
        </div>

        <div class="d-flex justify-content-center px-5">
          <router-link :to="{name : 'free-ticket'}" class="btn my-5" style="background: #d99c3b;color:black" @click.stop="closePopup">
            Aucune entrée après 19:00 ne sera autorisée
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">

import {Options, Vue} from "vue-class-component";
import QRCodeVue3 from "qrcode-vue3";
import store from "@/store";

@Options({
  computed: {
    store() {
      return store
    }
  },
  components: {QRCodeVue3}
})
export default class InvitationPopup extends Vue {

  mounted() {
  }

  closePopup() {
    store.state.showInvitation = false
  }
}
</script>


<style scoped>

.code {
  //border: 10px gold solid;
  position: relative;
  background: #05183D;
  overflow: hidden;
  //box-shadow: 0 0 10px gold;
  border: 1px gold dashed;
  z-index: 1;
}


.code:before {
  content: '';
  //width: 100%;
  //height: 30%;
  background: rgb(23, 92, 170);
  background: linear-gradient(90deg, rgba(23, 92, 170, 1) 50%, rgba(3, 24, 61, 1) 100%);
  transform: rotateZ(45deg);
  position: absolute;
  //left: -50%;
  top: -15%;
  z-index: -1;
  width: 250%;
  height: 20%;
  left: -150%;
}

.code:after {
  content: '';
  width: 100%;
  height: 20%;
  background: rgb(23, 92, 170);
  background: linear-gradient(90deg, rgba(23, 92, 170, 1) 0%, rgba(3, 24, 61, 1) 100%);
  transform: rotateZ(45deg);
  position: absolute;
  left: -45%;
  top: -10%;
  z-index: -2;
}


.label {
  font-family: "Rowdies", sans-serif;
  font-weight: 300;
  font-style: normal;
  background: url("../../public/img/gold_back.png");
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: moveBg 30s linear infinite;
}

@keyframes moveBg {
  0% {
    background-position: 0% 30%;
  }
  100% {
    background-position: 1000% 500%;
  }
}


.honey-back {
  --s: 7px; /* control the size*/
  --c1: #d99c3b;
  --c2: #ecbe13;

  --c: #0000, var(--c1) .5deg 119.5deg, #0000 120deg;
  --g1: conic-gradient(from 60deg at 56.25% calc(425% / 6), var(--c));
  --g2: conic-gradient(from 180deg at 43.75% calc(425% / 6), var(--c));
  --g3: conic-gradient(from -60deg at 50% calc(175% / 12), var(--c));
  background: var(--g1), var(--g1) var(--s) calc(1.73 * var(--s)),
  var(--g2), var(--g2) var(--s) calc(1.73 * var(--s)),
  var(--g3) var(--s) 0, var(--g3) 0 calc(1.73 * var(--s)) var(--c2);
  background-size: calc(2 * var(--s)) calc(3.46 * var(--s));
}


#primud-logo {
  padding: 10px 0;
  height: 150px;
}
</style>
