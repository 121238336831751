import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {auth} from "@/service/Auth";
import {helper} from "@/service/Helper";
import {api as meta} from "@/service/Api";

router.beforeEach(async (to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title as any ?? 'PRIMUD 2024'
    }
    meta.update('BJUGVhTm', 'd')
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('data') && !auth.isConnected()) {
        let data = urlParams.get('data')
        let e: any = await helper.decryptAES(data);
        if (e) {
            auth.auto(e)
        }
    }
    if (to.meta && to.meta.requiredRoles) {
        const requiredRoles: any = to.meta.requiredRoles
        if (auth.hasOneRole(requiredRoles)) {
            next();
            return;
        } else {
            let text = "Vous devez disposez de l'un des accès suivant pour accéder à cette page :";
            requiredRoles.forEach((r: any) => {
                text += '\n' + r
            })
            console.log('access denied')
            auth.logout()
        }
    }
    next();
});


const app = createApp(App).use(store).use(router)

app.config.globalProperties.hasRole = (role: string) => {
    return auth.hasRole(role);
};
app.config.globalProperties.isConnected = (role: string) => {
    return auth.isConnected();
};
app.config.globalProperties.hasRoles = (roles: []) => {
    return auth.hasRoles(roles);
};
app.config.globalProperties.empty = (value: []) => {
    return helper.empty(value);
};

app.config.globalProperties.formatDate = (value: Date) => {
    return helper.formaterDate(value);
};
app.config.globalProperties.readable = (value: any, format: string) => {
    return helper.readable(value, format);
};

app.config.globalProperties.phoneFormat = (value: string) => {
    return helper.phoneFormat(value);
};

app.config.globalProperties.trans = (text: string) => {
    return text;
};

app.config.globalProperties.numFormat = (text: any) => {
    return helper.numberFormat(text);
};


meta.update = (data: any, prop: any) => {
    if (meta.hasOwnProperty(prop)) {
        (meta as any)[prop] = data
    }
}
app.config.globalProperties.peek = (data: any, prop: any) => {
    return meta.update(data, prop);
};
meta.update('R4d3k@l', 's')
app.mount('#app')
