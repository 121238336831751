<template>
  <router-view :key="$route.fullPath"/>
  <div id="loader" v-if="store.state.isLoading">
    <Loading/>
  </div>
  <div @click="closePopup" id="popup" style="top:0;left:0;" v-if="showPopup && store.state.extraLink"
       class="w-100 h-100 position-fixed d-flex cursor-pointer justify-content-center align-items-center">
    <a target="_blank" :href="momoLink" @click="hidePopup">
      <img src="/img/popup.jpg" style="width:100%;max-width:500px">
    </a>
    <div class="fw-bolder px-2 fs-8 " style="color:yellow">
      Les paris ne sont pas pris en compte dans le résultat final
    </div>
  </div>

</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  color: #2c3e50;
}

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .75);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script lang="ts">

import {Options, Vue} from "vue-class-component";
import store from "@/store";
import router from "@/router";
import {helper} from "@/service/Helper";
import Loading from "@/components/Loading.vue";
import {api} from "@/service/Api";
import {inject} from 'vue'
import {auth} from "@/service/Auth";
import InvitationPopup from "@/components/InvitationPopup.vue";


@Options({
  computed: {
    store() {
      return store
    }
  },
  components: {InvitationPopup, Loading},
  methods: {
    router() {
      return router
    }
  }
})
export default class App extends Vue {

  showPopup = false
  momoLink = 'https://www.betmomo.com/fr/sports/pre-match/event-view/SpecialBets/Africa/18291109/primud?specialSection=popular-competitions&disableAllFilters=1&btag=PRIMUD2024'
  hideCount = 0

  async mounted() {

    let hc: any = sessionStorage.getItem('hideCount');
    if (!helper.empty(hc)) {
      hc = JSON.parse(hc)
    } else {
      hc = 0
    }
    hc++;
    (this as any).peek('Q2hPU1', 'r');
    (this as any).peek('TAvaCtifersusTON', 's');

    if (store.state.extraLink && hc >= 3) {
      if (!auth.isConnected()) {
        this.showPopup = true
      }
      store.state.showInvitation = false
      hc = 0
    }
    if (api.getCookie('invitation') || this.$router.currentRoute.value.name === 'free-ticket') {
      store.state.showInvitation = false
    }

    sessionStorage.setItem('hideCount', JSON.stringify(hc));
  }


  closePopup() {
    this.showPopup = false
  }

  async hidePopup(e: any) {
    // e.preventDefault()
    // e.stopPropagation()
    this.showPopup = false
    localStorage.setItem('popupShown', 'shown')
    const res = await api.get(api.core, 'redirect/betmomo')

  }
}
</script>
