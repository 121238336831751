import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

let adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/editor',
        name: 'admin-category-list',
        component: () => import('@/views/app/CategoryView.vue'),
        meta: {
            title: 'Categories',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/editor/auto',
        name: 'admin-auto',
        component: () => import('@/views/app/Auto.vue'),
        meta: {
            title: 'Categories',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/editor/qrcodes',
        name: 'admin-qrcode',
        component: () => import('@/views/app/QrManager.vue'),
        meta: {
            title: 'Categories',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/editor/qr/reader',
        name: 'qr-reader',
        component: () => import('@/views/app/QrReader.vue'),
        meta: {
            title: 'Lecteur QR',
            roles: ['ROLE_MANAGER']
        },
    },
    {
        path: '/editor/categorie/:category',
        name: 'admin-nomine-list',
        component: () => import('@/views/app/NomineView.vue'),
        meta: {
            title: 'Nominés',
            roles: ['ROLE_ADMIN']
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

export default adminRoutes
